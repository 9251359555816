import { InputAdornment, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './Input.module.scss';
import Button, { ButtonVariants } from '../Button/Button';
import Svg from '../Svg';

type InputProps = {
  value: string | number,
  setValue: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  onKeyDown?: (e: React.KeyboardEvent) => void,
  onFocus?: (e: React.FocusEvent) => void,
  type?: string,
  id: string,
  label?: string,
  required?: boolean,
  fullWidth?: boolean,
  errorMessage?: string;
  disabled?: boolean,
  className?: string,
  size?: 'small' | 'medium',
  name?: string,
  placeholder?: string,
  icon?: React.ReactNode,
  iconSize?: number,
};

const Input = ({
  value,
  setValue,
  onBlur,
  onKeyDown,
  type = 'text',
  id,
  label,
  required = false,
  fullWidth = false,
  disabled = false,
  className,
  size,
  name,
  errorMessage,
  onFocus,
  placeholder,
  icon,
  iconSize = 16,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const isPasswordInput = type === 'password';
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <div className={styles.inputContainer}>
        <TextField
          value={value}
          type={isPasswordInput ? passwordInputType : type}
          onChange={setValue}
          onBlur={onBlur}
          error={!!errorMessage}
          disabled={disabled}
          className={classNames(styles.input, className, {
            [styles.disabled]: disabled,
            'field-error': errorMessage,
          })}
          id={id}
          label={label}
          variant='outlined'
          required={required}
          fullWidth={fullWidth}
          size={size}
          name={name}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          placeholder={placeholder}
          slotProps={isPasswordInput ? {
            input: {
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    ariaLabel='toggle password visibility'
                    icon={showPassword ? <Svg name='eyeOpen' /> : <Svg name='eyeClose' />}
                    variant={ButtonVariants.ICON}
                    onClick={handleClickShowPassword}
                  />
                </InputAdornment>
              ),
            },
          } : {}}
        />
        {icon && (
        <div
          className={styles.icon}
          style={{
            width: iconSize,
            height: iconSize,
          }}
        >
          {icon}
        </div>
        )}
      </div>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};

export default Input;
