'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import styles from './LoginPage.module.scss';
import Input from '../../../UIKit/Input/Input';
import Button from '../../../UIKit/Button/Button';
import CheckboxItem from '../../../UIKit/CheckboxItem/CheckboxItem';

const LoginPage = () => {
  const t = useTranslations('loginPage');
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState<string>('');
  return (
    <>
      <div className={styles.info}>
        <h1 className={styles.title}>{t('Log in to SDS')}</h1>
        <p className={styles.subtitle}>
          {t('Manage compliance and keep your chemical inventory up-to-date')}
        </p>
      </div>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          signIn('credentials', {
            email,
            password,
            redirect: false,
          }).then((result) => {
            if (result?.ok) {
              router.push('/');
            } else {
              setLoginError(result?.error ?? '');
            }
          });
        }}
      >
        <div className={styles.form__fields}>
          <Input
            id='loginEmailInput'
            label={t('Email')}
            type='email'
            value={email}
            setValue={(e) => setEmail(e.target.value)}
          />
          <Input
            id='loginPasswordInput'
            label={t('Password')}
            type='password'
            value={password}
            setValue={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.form__actions}>
          <CheckboxItem
            label={t('Remember me')}
            labelClassName={styles.buttonFont}
            value={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
          />
          <Link
            className={styles.linkTextSmall}
            href='/forgot-password'
          >
            {t('Forgot your password?')}
          </Link>
        </div>
        <Button
          className={styles.form__button}
          type='submit'
        >
          {t('Log In')}
        </Button>
        {loginError && <p className={styles.error}>{loginError}</p>}
      </form>
    </>
  );
};

export default LoginPage;
